import {post} from '@/utils/request'

//规则列表
export function PostSpecList(data) {
  return post('adminapi/spec_list',data)
}
//规则增加
export function PostSpecAdd(data) {
    return post('adminapi/spec_add',data)
  }
  //规则删除
export function PostSpecDelete(data) {
    return post('adminapi/spec_delete',data)
  }
  //规则修改
export function PostSpecUpdate(data) {
    return post('adminapi/spec_update',data)
  }
  //规格项列表
export function PostspecItemList(data) {
    return post('adminapi/spec_item_list',data)
  }
    //规格项增加
export function PostspecItemAdd(data) {
    return post('adminapi/spec_item_add',data)
  }
    //规格项修改
export function PostspecItemUpdate(data) {
    return post('adminapi/spec_item_Update',data)
  }
    //规格项删除
export function PostspecItemDelete(data) {
    return post('adminapi/spec_item_delete',data)
  }
      //服务模型详情
export function PostServiceMouldDetail(data) {
    return post('adminapi/service_mould_detail',data)
  }