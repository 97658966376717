import {post} from '@/utils/request'

//服务模型列表
export function PostServiceMouldList(data) {
  return post('adminapi/service_mould_list',data)
}
//服务模型增加
export function PostServiceMouldAdd(data) {
  return post('adminapi/service_mould_add',data)
}
//服务模型更新
export function PostServiceMouldUpdate(data) {
  return post('adminapi/service_mould_update',data)
}
//服务模型删除
export function PostServiceMouldDelete(data) {
  return post('adminapi/service_mould_delete',data)
}
//站点列表
export function PostAdminapiSiteList(data) {
  return post('adminapi/site_list',data)
}
//站点服务列表
export function PostAdminapiSiteServiceList(data) {
  return post('adminapi/site_service_list',data)
}

//服务规格-定价信息-批量提交
export function PostSpecPriceSubmit(data) {
  return post('adminapi/spec_price_submit',data)
}
//服务规格-列表
export function PostSpecPriceList(data) {
  return post('adminapi/site_service_spec_price_list',data)
}
