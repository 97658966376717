<template>
  <div class="user">
    <table border="1" ref="multipleTable" class="table" cellspacing="0">
      <tr>
        <td colspan="3" style="padding: 10px" class="titleSize">商品规格类型：</td>
        <td :colspan="formSpecValue.length+6" style="padding: 10px">
          <el-select
            @change="onChangeMould"
            v-model="form.value1"
            placeholder="请选择"
          >
            <el-option
              v-for="item in options"
              :key="item.mould_id"
              :label="item.mould_name"
              :value="item.mould_id"
            >
            </el-option>
          </el-select>
        </td>
      </tr>
      <tr>
        <td :colspan="formSpecValue.length+7" class="titleSize1">商品规格：</td>
        <!-- <td rowspan="1" class="titleSize1">商品属性：</td> -->
      </tr>
      <tr v-for="(item, index) in formSpecValue" :key="item.spec_id">
        <td class="titleSize">
          {{ item.spec_name }}<span>:</span>
        
        </td>
        <td :colspan="formSpecValue.length+7">
          <el-checkbox-group ref="mult" v-model="form.checkList1">
            <el-checkbox
              name="checkbox"
              @change="onChangeChebox(index, index1, item1)"
              :label="item1.item_id"
              v-for="(item1, index1) in item.spec_item_list"
              :key="item1.item_id"
              >{{ item1.item_name }}</el-checkbox
            >
          </el-checkbox-group>
        </td>
      </tr>
      <tr v-if="ArrTableThree.length>0">
        <td   class="titleSize1">规格值</td>
        <td class="titleSize1">购买价</td>
        <td class="titleSize1">成本价</td>
        <td class="titleSize1">照片链接</td>
        <td class="titleSize1">库存</td>
        <td class="titleSize1">单位</td>
        <td class="titleSize1" :colspan="formSpecValue.length-ArrTableThree.length">操作</td>
      </tr>
      <tr v-else>
        <td  class="titleSize1">规格值</td>
        <td class="titleSize1">购买价</td>
        <td class="titleSize1">成本价</td>
        <td class="titleSize1">照片链接</td>
        <td class="titleSize1">库存</td>
        <td class="titleSize1">单位</td>
        <td class="titleSize1">操作</td>
      </tr>
      <template v-if="tableTdArr.length>0">
      <tr v-for="item in tableTdArr" :key="item.id">
        <td >
          <div v-for="(item1,index1) in item.price_key_name" :key="index1">
          <el-tag >{{ item1}}</el-tag> 
        </div> 
        </td>
        <td>
          <el-input type="number" v-model="item.price"></el-input>
        </td>
        <td>
          <el-input type="number" v-model="item.oldPrice"></el-input>
        </td>
        <td>
          <el-input type="text" v-model="item.price_image"></el-input>
        </td>
        <td>
          <el-input type="number" v-model="item.stock"></el-input>
        </td>
        <td>
          <el-input type="text" v-model="item.unit"></el-input>
        </td>
        <td>
          <el-button size="mini" type="primary" @click="onAddServeItem(item)" plain>增加</el-button>
          <el-button size="mini" type="danger" @click="onDeleteServeItem(item)"  plain>删除</el-button>
          <el-button size="mini" type="success" @click="onUpdateAddServeItem(item)" plain>修改</el-button>
        </td>
      </tr>
    </template>
    <template v-else>
      <tr v-for="item in tableTdArrList" :key="item.id">
        <td >
          <div v-for="(item1,index1) in item.price_key_name" :key="index1">
          <el-tag >{{ item1}}</el-tag> 
        </div> 
        </td>
        <td>
          <el-input type="number" v-model="item.price"></el-input>
        </td>
        <td>
          <el-input type="number" v-model="item.oldPrice"></el-input>
        </td>
        <td>
          <el-input type="text" v-model="item.price_image"></el-input>
        </td>
        <td>
          <el-input type="number" v-model="item.stock"></el-input>
        </td>
        <td>
          <el-input type="text" v-model="item.unit"></el-input>
        </td>
        <td>
          <el-button size="mini" type="primary" @click="onAddServeItem(item)" plain>增加</el-button>
          <el-button size="mini" type="danger" @click="onDeleteServeItem(item)"  plain>删除</el-button>
          <el-button size="mini" type="success" @click="onUpdateAddServeItem(item)" plain>修改</el-button>
        </td>
     </tr>
    </template>
    </table>
    <el-button @click="onAllServeItem" v-if="tableTdArr.length>0" style="margin-top: 20px;" plain type="primary">全部提交</el-button>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  PostServiceMouldList,
  PostAdminapiSiteList,
  PostAdminapiSiteServiceList,
  PostSpecPriceSubmit,
  PostSpecPriceList
} from "@/api/serveList/serveList.js";
import {
  PostSpecList,
  PostServiceMouldDetail,
} from "@/api/specificationList/specificationList.js";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    var validateItem = (rule, value, callback) => {
      if (this.form.item === "") {
        callback(new Error("请输入人数"));
      } else {
        callback();
      }
    };
    //这里存放数据
    return {
      rules: {
        item: [{ validator: validateItem, trigger: "blur" }],
      },
      //总条数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: "",
        page_code: 1,
        page_num: 15,
      },
      formSpecValue: [], //商品规格项列表
      form: {
        service_id: "3",
        value1: "",
        value2: "",
        checkList1: [],
        checkList2: [],
      },
      props: {
        children: "sub",
        value: "id",
        label: "name",
      },
      //表格数据
      tableData: [],
      token: {
        token: window.sessionStorage.getItem("token"),
      },
      ArrTableTwo:[],//二维数组数据ID
      ArrTableThree:[],//二维数组数据规格
      ArrTableText:[],//二维数组数据文字
      itemIdArr:[],//规格ID存储值
      itemNameArr:[],//规格名称存储在
      tableTdArr:[],//动态表格td
      tableTdArrList:[],//表格列表
      formLabelWidth: "100px",
      imgParam: {
        path: "contract/template",
      },
      fileList: [],
      //控制弹窗--新增修改
      dialogFormVisible: false,
      addFlag: false,
      lookFalg: false,
      updataFalg: false,
      options: [],
      options1: [],
      flag:true
    };
  },
  //监听属性 类似于data概念
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl;
    },
  },
  //监控data中的数据变化
  watch: {
    itemNameArr(newVal, oldVal) {
     this.tableTdArr=[];
      newVal.forEach((item,index)=>{
          this.tableTdArr.push({
          service_id:this.form.service_id,//服务ID
          itemNameArr:this.itemNameArr[index].includes("-")?this.itemNameArr[index].split("-"):this.itemNameArr[index].split(""),//模型类型名称
          // item_name:item_name,//模型名称
          price:199.00,//价格
          oldPrice:999.99,//成本价格
          stock:100,//库存
          unit:'间',//单位
          price_key:this.itemIdArr[index].toString().includes("-")?this.itemIdArr[index].toString().split("-"):this.itemIdArr[index].toString().split(" "),//模型组合ID
          price_key_name:this.itemNameArr[index]?this.itemNameArr[index].split("-"):this.itemNameArr[index].split(""),//模型组合名称
          price_image:'http://zxtl.hlf360.xyz/storage/image/20230317/315ec964baccff5f4f81150f2a004a9b.jpg',//照片链接
          id:Math.floor(Math.random() * 1000000) + 1
        })
      })
    
    },
  },
  //方法集合
  methods: {
    //全部提交
    onAllServeItem(){
       let arr=[]
       this.tableTdArr.forEach((item)=>{
          arr.push({
            service_id:this.form.service_id,
        price:item.price,
        price_image:item.price_image,
        price_key:item.price_key.join("-"),
        price_key_name:item.price_key_name.join("-")
          })
       })
       var data={
        service_id:this.form.service_id,
        spec_price_json:JSON.stringify(arr)
      }
     console.log(data);
    this.PostSpecPriceSubmit(data);
    },
    //增加按钮服务规格定价
    onAddServeItem(item){
      let arr=[{
        service_id:this.form.service_id,
        price:item.price,
        price_image:item.price_image,
        price_key:item.price_key.join("-"),
        price_key_name:item.price_key_name.join("-")
      }]
      var data={
        service_id:this.form.service_id,
        spec_price_json:JSON.stringify(arr)
      }
     // console.log(data);
      this.PostSpecPriceSubmit(data);
       //    console.log(item)
    },
       //修改按钮服务规格定价
       onUpdateServeItem(item){
        console.log(item)
},
   //删除按钮服务规格定价
   onDeleteServeItem(item){
    console.log(item)
},
    //房间多选框
    onChangeChebox(index, index1, item1) {
      //创建二维数组存储值
      if(this.flag){
         this.formSpecValue.forEach((item,index) => {
          this.ArrTableTwo[index]=[]
          this.ArrTableText[index]=[]
          this.ArrTableThree=[]
      });
      this.flag=false;
      }
      let spec_name = this.formSpecValue[index].spec_name; //模型类型名称
      let item_id = this.formSpecValue[index].spec_item_list[index1].item_id; //模型ID
      let item_name =
        this.formSpecValue[index].spec_item_list[index1].item_name; //模型名称
      var index10 = this.form.checkList1.findIndex((item) => {
        return item == item1.item_id;
      });
      // 如果index为-1则为取消选中
      if (index10 == -1) {
        //console.log(index,index1);
        let j=this.ArrTableTwo[index].indexOf(item_id);
        let jj=this.ArrTableText[index].indexOf(item_name);
        let jjj=this.ArrTableThree.indexOf(spec_name);
        this.ArrTableTwo[index].splice(j,1);
        this.ArrTableText[index].splice(jj,1);
        if(this.ArrTableText[index].length==0){
          this.ArrTableThree.splice(jjj,1);
        } 
        this.itemIdArr= this.allGroups(this.ArrTableTwo)
        this.itemNameArr=this.allGroups(this.ArrTableText)
      } else {
      //  console.log(index,index1);
        this.ArrTableTwo[index].push(item_id);
        this.ArrTableText[index].push(item_name);
        let jjj=this.ArrTableThree.indexOf(spec_name);
        if(this.ArrTableText[index].length==1){
          this.ArrTableThree.push(spec_name);
        } 
        this.itemIdArr=this.allGroups(this.ArrTableTwo);
        this.itemNameArr=this.allGroups(this.ArrTableText);
        //console.log(index,index1);

      }
      //console.log(this.formSpecValue[index].spec_name,this.formSpecValue[index].spec_id);
    },
    //主题多选框
    onChange2(value) {
      console.log(value);
    },
    //站点服务下拉框
    onChangeService(value) {
      console.log(value);
    },
    //模型服务下拉框
    onChangeMould(value) {
      this.form=this.$options.data().form;
      this.ArrTableTwo=[]
      this.ArrTableText=[]
      this.ArrTableThree=[]
      this.tableTdArr=[]
      this.itemIdArr=[]
      this.itemNameArr=[]
      this.formSpecValue=[]
      // this.$nextTick(()=>{
      //   console.log(this.$refs.multipleTable)
      //   this.$refs.multipleTable.clearSelection()
      // })
      this.flag=true;
      this.PostServiceMouldDetail({
        mould_id: value,
      });
     
    },
    //二维数组组合
    multi(arr1,arr2){
  if(arr1.length==0){ // arr1 是空数组 []
    return arr2;
  }
  if(arr2.length==0){
    return arr1;
  }
  let res = [];
  for(let i=0;i<arr1.length;i++){
    for(let j=0;j<arr2.length;j++){
      res.push(String(arr1[i])+'-'+String(arr2[j]))
    }
  }
  return res;
},
allGroups(arr){
  return arr.reduce((total,cur)=>{
    let ress = this.multi(total,cur);
   // console.log(ress);
    return ress;
  },[]);
},
    /**-----------------网络请求生命周期函数--------------------- */
    //服务规格定价列表
    async PostSpecPriceList(data){
        let res=await PostSpecPriceList(data);
        console.log(res)
        if(res.data.status==1){
         res.data.result.forEach((item)=>{
          this.tableTdArrList.push({
            service_id:item.service_id,//服务ID
          itemNameArr:item.price_key_name.includes("-")?item.price_key_name.split("-"):item.price_key_name.split(" "),//模型类型名称
          // item_name:item_name,//模型名称
          price:item.price,//价格
          oldPrice:199.99,//成本价格
          stock:100,//库存
          unit:'间',//单位
          price_key:item.price_key.toString().includes("-")?item.price_key.toString().split("-"):item.price_key.toString().split(" "),//模型组合ID
          price_key_name:item.price_key_name.includes("-")?item.price_key_name.split("-"):item.price_key_name.split(" "),//模型组合名称
          price_image:'http://zxtl.hlf360.xyz/storage/image/20230317/315ec964baccff5f4f81150f2a004a9b.jpg',//照片链接
          id:Math.floor(Math.random() * 10000) + 1
          })
         }) 
        
        this.$message({
          message: res.data.message,
          type: 'success'
        });
       }
    },
    //服务规格定价信息
    async PostSpecPriceSubmit(data){
       let res=await PostSpecPriceSubmit(data);
       if(res.data.status==1){
        this.$message({
          message: res.data.message,
          type: 'success'
        });
        this.PostSpecPriceList({
          service_id:this.form.service_id
        })
       }
    },
    //规则列表
    async PostServiceMouldDetail(data) {
      let res = await PostServiceMouldDetail(data);
      // console.log(res.data.result)
      this.formSpecValue = res.data.result;
    },
    //模型列表
    async PostServiceMouldList(data) {
      let res = await PostServiceMouldList(data);
      this.options = res.data.result;
      this.form.value1 = this.$route.params.item.mould_id;
      this.PostServiceMouldDetail({
        mould_id: this.$route.params.item.mould_id,
      });
    },
    //站点列表
    async PostAdminapiSiteList(data) {
      let res = await PostAdminapiSiteList(data);
      console.log(res.data.result);
      //   this.options=res.data.result;
      //   this.form.value1=res.data.result[0].mould_id;
      //   this.PostServiceMouldDetail({
      //   mould_id:res.data.result[0].mould_id,
      //  })
    },
    //站点服务列表
    async PostAdminapiSiteServiceList(data) {
      let res = await PostAdminapiSiteServiceList(data);
      console.log(res.data.result);
      //   this.options=res.data.result;
      //   this.form.value1=res.data.result[0].mould_id;
      //   this.PostServiceMouldDetail({
      //   mould_id:res.data.result[0].mould_id,
      //  })
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    console.log(this.$route.params)
    this.PostServiceMouldList();
    this.PostSpecPriceList({
          service_id:this.$route.params.item.service_id
        })
    this.form.service_id = this.$route.params.item.service_id;
  },

  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
};
</script>
  
  <style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
.title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.diaBox {
  display: flex;
  justify-content: center;
}
.imgUrl {
  margin-left: 50%;
  transform: translateX(-50%);
  width: 250px;
  height: 150px;
  overflow: hidden;
  text-align: center;
}
.my-label {
  background: #e1f3d8;
}
.table {
  margin-top: 40px;
  width: 100%;

  /* border: 1px solid rgb(248,248,248); */
  /* min-height: 200px; */
  /* border-color:rgb(250, 250, 250)!important; */
  /* background-color: white; */
  font-size: 14px;
}
.titleSize {
  font-size: 14px;
  color: #666;
}
.titleSize1 {
  font-size: 14px;
}
td {
  padding: 20px 10px;
}
</style>